$tbdvox-green: #00af66;
$tbdvox-green-rgb: 0, 175, 102;
$tbdvox-green-light: #75c6a1;
$tbdvox-green-dark: #008759;
$tbdvox-blue: #0e103e;
$tbdvox-white: #ffffff;
$tbdvox-black: #231f20;
$tbdvox-cool-gray-c: #efeff0;
$tbdvox-cool-gray-3c: #e6e7e8;
$tbdvox-cool-gray-5c: #bcbec0;
$tbdvox-heading-font: "TeleVoiceSemibold";
$tbdvox-main-font: "akzidenz-grotesk", "Source Sans Pro", sans-serif;

$primary: $tbdvox-blue;

@import "~/node_modules/bootstrap/scss/bootstrap";

.td-carousel-item {
  .card {
    height: 65vh;
    container-type: size;
  }

  .card-body {
    height: 80cqh;
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
      max-height: 50%;
    }

    .card-text {
      overflow: hidden;
    }
  }
}

.card-footer {
  justify-content: center;
  display: flex;
}

/* The carousel controls is built using an a-tag. This will remove the underline. */
.carousel-control-next,
.carousel-control-prev {
  text-decoration: none;
}

.carousel-control-next-icon {
  width: 5rem;
  height: 5rem;
}

.carousel-control-prev-icon {
  width: 5rem;
  height: 5rem;
}

.carousel-inner {
  height: 70vh;
}
